import {
  Box,
  Grommet,
  Image,
  Anchor,
  Text,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ResponsiveContext,
  Paragraph,
} from "grommet";
import { Cursor, Github, Twitter } from "grommet-icons";
import React from "react";

const theme = {
  global: {
    font: { family: "Inconsolata", size: "18px", height: "20px" },
  },
};

function App() {
  return (
    <Grommet theme={theme}>
      <Box direction="column" background="black" height="100%">
        <Box
          direction="row"
          align="center"
          background="black"
          animation={{
            type: "fadeOut",
            delay: 4200,
            duration: 12000,
          }}
        >
          <Anchor href="https://www.linuxscrew.com/chmod-777" target={"_blank"}>
            <Text color={"white"} size="large">
              $ chmod 777 governance -R
            </Text>
          </Anchor>
        </Box>

        <Box
          direction="column"
          background="red"
          weight="bold"
          align="start"
          animation={{
            type: "fadeOut",
            delay: 4200,
            duration: 22000,
          }}
        >
          <Text color={"white"} size="large" weight={"bold"}>
            $ reboot
          </Text>
        </Box>

        <ResponsiveContext.Consumer>
          {(size) =>
            size === "small" ? (
              <>
                <Box
                  height={"100%"}
                  direction="column"
                  align="center"
                  gap="medium"
                >
                  <Box
                    align="center"
                    margin={"22px"}
                    direction="column"
                    pad={"small"}
                    gap="small"
                  >
                    <RabbitHoledCard />
                    <Links />
                  </Box>
                  <Box>
                    <RootTabs />
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box
                  height={"100%"}
                  direction="row"
                  align="center"
                  justify="center"
                  gap="medium"
                >
                  <Box
                    align="start"
                    margin={"4%"}
                    direction="column"
                    pad={"small"}
                    gap="small"
                  >
                    <RabbitHoledCard />
                    <Links />
                  </Box>
                  <Box>
                    <RootTabs />
                  </Box>
                </Box>
              </>
            )
          }
        </ResponsiveContext.Consumer>
      </Box>
      <Box>
        <ResponsiveContext.Consumer>
          {(size) =>
            size === "small" ? (
              <>
                <Box background="#d6d37a">
                  <Text textAlign="center" size="medium" color={"black"}>
                    {" "}
                    "you don't need eyes to see, you need vision".
                  </Text>
                </Box>
                <Box background={"black"} height="xxsmall">
                  <Anchor
                    margin={"small"}
                    alignSelf="center"
                    target={"_blank"}
                    color={"white"}
                    href="https://www.youtube.com/embed/cyQ0s0J1fhY"
                  >
                    {"> "}listen {">"}
                  </Anchor>
                </Box>
              </>
            ) : (
              <>
                <Box background="#d6d37a">
                  <Text textAlign="center" size="large" color={"black"}>
                    {" "}
                    "you don't need eyes to see, you need vision".
                  </Text>
                </Box>
                <Box background={"black"} height="xxsmall">
                  <Anchor
                    margin={"small"}
                    alignSelf="center"
                    target={"_blank"}
                    color={"white"}
                    href="https://www.youtube.com/embed/cyQ0s0J1fhY"
                  >
                    {"> "}listen {">"}
                  </Anchor>
                </Box>
              </>
            )
          }
        </ResponsiveContext.Consumer>
      </Box>
    </Grommet>
  );
}

const ProjectCards = () => {
  return (
    <ResponsiveContext.Consumer>
      {(size) =>
        size === "small" ? (
          <Box
            direction="column"
            gap={"large"}
            align={"center"}
            margin="medium"
          >
            <Card height="small" width="small" background={"#5b92e5"}>
              <CardHeader pad="small" margin={"xsmall"}>
                <Text size="small" color={"white"} align={"center"}>
                  A World Peace
                </Text>
              </CardHeader>

              <CardBody background={"black"} pad="large">
                <Text size="small"> Mutual Assured Regeneration</Text>
              </CardBody>
              <CardFooter background="light-2" justify="center">
                <Anchor
                  href="https://www.aworldpeace.org"
                  target={"_blank"}
                  icon={<Cursor color="black" />}
                  hoverIndicator
                ></Anchor>
                <Anchor
                  href="https://github.com/demo-verse"
                  target={"_blank"}
                  icon={<Github color="black" />}
                  hoverIndicator
                ></Anchor>

                <Anchor
                  href="https://discord.gg/sqjdyCWNAR"
                  target={"_blank"}
                  referrerPolicy="noreferrer"
                  icon={
                    <Image
                      src="./discord-logo.png"
                      height={"24px"}
                      width={"24px"}
                    ></Image>
                  }
                ></Anchor>

              </CardFooter>
            </Card>

            <Card height="small" width="small">
              <CardHeader pad="small" background={"red"}>
                <Anchor href="https://expressionsofpeace.org" target={"_blank"}>
                  <Text color={"black"} size="small">
                    {" "}
                    Expressions of Peace
                  </Text>
                </Anchor>
              </CardHeader>

              <CardBody background={"black"} pad={"large"} >
                <Anchor href="https://expressionsofpeace.org" target={"_blank"}>
                  <Text size="small" color={"white"}>
                    {" "}
                    Peer to peer diplomacy scaler
                  </Text>
                </Anchor>
              </CardBody>
              <CardFooter background="light-2" justify="center">
                <Anchor
                  href="https://expressionsofpeace.org"
                  target={"_blank"}
                  icon={<Cursor color="black" />}
                  hoverIndicator
                ></Anchor>
                <Anchor
                  href="https://github.com/demo-verse/expressions-of-peace"
                  target={"_blank"}
                  icon={<Github color="black" />}
                  hoverIndicator
                ></Anchor>
                <Anchor
                  href="https://discord.gg/sqjdyCWNAR"
                  target={"_blank"}
                  referrerPolicy="noreferrer"
                  icon={
                    <Image
                      src="./discord-logo.png"
                      height={"24px"}
                      width={"24px"}
                    ></Image>
                  }
                ></Anchor>

              </CardFooter>
            </Card>

            <Card height="small" width="small" background="purple">
              <CardHeader pad="small" background={"orange"}>
                <Text color={"black"} size="small">
                  New Square Media
                </Text>
              </CardHeader>

              <CardBody background={"black"} pad={"large"}>
                <Anchor href="https://newsquare.media" target={"_blank"}>
                  <Text size="small" color={"white"}>
                    {" "}
                    Art discovery and streaming platform
                  </Text>
                </Anchor>
              </CardBody>

              <CardFooter background="light-2" justify="center">
                <Box direction="horizontal" gap="small">
                  <Anchor
                    href="https://newsquare.media"
                    target={"_blank"}
                    icon={<Cursor color="black" />}
                    hoverIndicator
                  ></Anchor>

                  <Anchor
                    href="https://discord.gg/EXN7Mta2wu"
                    target={"_blank"}
                    referrerPolicy="noreferrer"
                    icon={
                      <Image
                        src="./discord-logo.png"
                        height={"24px"}
                        width={"24px"}
                      ></Image>
                    }
                  ></Anchor>
                </Box>
              </CardFooter>
            </Card>

            <Card height="small" width="small" background="black">
              <CardHeader pad="small" background={""}>
                <Anchor
                  href="https://stateful.art"
                  target={"_blank"}
                  color={"white"}
                >
                  st
                  <Text color="red" size="medium">
                    art
                  </Text>{" "}
                </Anchor>
              </CardHeader>

              <CardBody background={"black"} pad="large">
                <Anchor href="https://stateful.art" target={"_blank"}>
                  <Text color={"white"} size="small">
                    {" "}
                    Start a World Peace Technology A.Ş.
                  </Text>
                </Anchor>
              </CardBody>
              <CardFooter background="light-2" justify="center" gap="2xsmall">
                <Anchor
                  href="https://stateful.art"
                  target={"_blank"}
                  icon={<Cursor color="black" />}
                  hoverIndicator
                ></Anchor>
                <Anchor
                  href="https://github.com/stateful-art/"
                  target={"_blank"}
                  icon={<Github color="black" />}
                  hoverIndicator
                ></Anchor>

                <Anchor
                  href="https://twitter.com/statefulArt"
                  target={"_blank"}
                  icon={<Twitter color="#1DA1F2" />}
                  hoverIndicator
                ></Anchor>
                <Anchor
                  href="https://discord.gg/EXN7Mta2wu"
                  target={"_blank"}
                  icon={
                    <Image
                      src="./discord-logo.png"
                      height={"24px"}
                      width={"24px"}
                    ></Image>
                  }
                ></Anchor>
              </CardFooter>
            </Card>
          </Box>
        ) : (
          <Box direction="column" margin={"medium"} gap="large">
            <Box direction="row" gap="medium">
              <Box direction="row" gap="medium">
                <Card height="medium" width="medium" background="black">
                  <CardHeader pad="small" background={"#5b92e5"}>
                    <Anchor
                      href="https://www.aworldpeace.org"
                      target={"_blank"}
                    >
                      <Text textAlign="center" size="medium" color={"white"}>
                        A World Peace
                      </Text>
                    </Anchor>
                  </CardHeader>
                  <CardBody background={"black"} pad="large">
                    <Box
                      margin={"small"}
                      align="center"
                      round="medium"
                      pad={"small"}
                      animation={{
                        type: "pulse",
                        delay: 0,
                        duration: 1000,
                      }}
                    >
                      <a
                        href="https://www.aworldpeace.org"
                        target={"_blank"}
                        rel="noreferrer"
                        color="white"
                      >
                        <Image
                          src="./projects/peace.png"
                          height="94px"
                          width="94px"
                        ></Image>
                      </a>
                    </Box>
                    <Text textAlign="center" size="medium">
                      Mutual Assured Regeneration
                      {/* <br></br>0 to billions to acknowledge, <br></br>0 to hundreds to seal it. */}
                    </Text>
                  </CardBody>

                  <CardFooter background="light-2" justify="center">
                    <Box direction="row" gap="small" size="large">
                      <Anchor
                        href="https://www.aworldpeace.org"
                        target={"_blank"}
                        icon={<Cursor color="black" />}
                      />

                      <Anchor
                        href="https://discord.gg/sqjdyCWNAR"
                        target={"_blank"}
                        referrerPolicy="noreferrer"
                        icon={
                          <Image
                            src="./discord-logo.png"
                            height={"24px"}
                            width={"24px"}
                          ></Image>
                        }
                      ></Anchor>
               

                      <Anchor
                        href="https://github.com/demo-verse"
                        target={"_blank"}
                        referrerPolicy="noreferrer"
                        icon={<Github color="black" />}
                      ></Anchor>

                      {/* <Anchor
                        href="https://miro.com/app/board/uXjVOX94E5k=/?invite_link_id=132783006721"
                        target={"_blank"}
                        referrerPolicy="noreferrer"
                        icon={<Stakeholder />}
                      ></Anchor> */}
                    </Box>
                  </CardFooter>
                </Card>

                <Card height="medium" width="medium" background="red">
                  <CardHeader pad="small">
                    <Anchor
                      href="https://expressionsofpeace.org"
                      target={"_blank"}
                    >
                      <Text textAlign="center" size="medium" color={"black"}>
                        Expressions of Peace
                      </Text>
                    </Anchor>
                  </CardHeader>

                  <CardBody background={"black"} pad="medium">
                    <Box align="center" round="medium" pad={"small"}>
                      {" "}
                      <a
                        href="https://expressionsofpeace.org"
                        target={"_blank"}
                        rel="noreferrer"
                        color="white"
                      >
                        <Image
                          src={"./projects/someone-express-peace.png"}
                          height="140px"
                          width="180px"
                        ></Image>{" "}
                      </a>
                      <Text textAlign="center" size="md" margin={"small"}>
                        Peer to peer diplomacy scaler
                      </Text>
                    </Box>
                  </CardBody>

                  <CardFooter background="light-2" justify="center">
                    <Box
                      direction="row"
                      gap="small"
                      size="medium"
                      pad={"xsmall"}
                    >
                      <Anchor
                        href="https://expressionsofpeace.org"
                        target={"_blank"}
                        icon={<Cursor color="black" />}
                      ></Anchor>
                      <Anchor
                        href="https://discord.gg/sqjdyCWNAR"
                        target={"_blank"}
                        referrerPolicy="noreferrer"
                        icon={
                          <Image
                            src="./discord-logo.png"
                            height={"24px"}
                            width={"24px"}
                          ></Image>
                        }
                      ></Anchor>
                      <Anchor
                        href="https://github.com/demo-verse/expressions-of-peace"
                        target={"_blank"}
                        icon={<Github color="black" />}
                        hoverIndicator
                      ></Anchor>

                   
                    </Box>
                  </CardFooter>
                </Card>
              </Box>
            </Box>

            {/* second row */}
            <Box direction="row" gap="medium">
              <Box direction="row" gap="medium">
                <Card height="medium" width="medium" background="black">
                  <CardHeader pad="small" background="orange">
                    <Anchor
                      color={"black"}
                      href="https://newsquare.media"
                      target={"_blank"}
                    >
                      <Text size="medium">New Square Media</Text>
                    </Anchor>
                  </CardHeader>

                  <CardBody background={"black"} pad={"medium"}>
                    {" "}
                    <Box align="center" round="medium">
                      {" "}
                      <Anchor
                        href="https://newsquare.media"
                        target={"_blank"}
                        rel="noreferrer"
                        color="white"
                      >
                        <Image
                          src={"./projects/newnewmedia.png"}
                          height="180px"
                          width="220px"
                        ></Image>{" "}
                      </Anchor>
                      <Text textAlign="center" size="medium">
                        Art discovery and streaming platform
                      </Text>
                    </Box>
                  </CardBody>

                  <CardFooter background="light-2" justify="center">
                    <Box
                      direction="row"
                      gap="medium"
                      size="large"
                      pad={"xsmall"}
                    >
                      <Anchor
                        href="https://newsquare.media"
                        target={"_blank"}
                        icon={<Cursor color="black" />}
                      ></Anchor>

                      <Anchor
                        href="https://github.com/stateful-art"
                        target={"_blank"}
                        icon={<Github color="black" />}
                      ></Anchor>
                    </Box>
                  </CardFooter>
                </Card>

                <Card height="medium" width="medium" background="black">
                  <CardHeader pad="small">
                    <Anchor href="https://stateful.art" target={"_blank"}>
                      <Text
                        textAlign="center"
                        size="large"
                        color={"white"}
                      ></Text>
                    </Anchor>
                  </CardHeader>

                  <CardBody background={"black"} pad="large" gap="large">
                    <Box align="center" round="medium">
                      {" "}
                      <a
                        href="https://stateful.art"
                        target={"_blank"}
                        rel="noreferrer"
                        color="white"
                      >
                        <Image
                          src={"./projects/start.png"}
                          height="160px"
                          width="160px"
                        ></Image>{" "}
                      </a>
                    </Box>
                    <Text textAlign="center" size="medium">
                      Start a World Peace Technology A.Ş.
                    </Text>
                  </CardBody>

                  <CardFooter background="light-2" justify="center">
                    <Box
                      direction="row"
                      gap="small"
                      size="medium"
                      pad={"xsmall"}
                    >
                      <Anchor
                        href="https://stateful.art"
                        target={"_blank"}
                        icon={<Cursor color="black" />}
                      ></Anchor>

                      <Anchor
                        href="https://discord.gg/EXN7Mta2wu"
                        target={"_blank"}
                        icon={
                          <Image
                            src="./discord-logo.png"
                            height={"24px"}
                            width={"24px"}
                          ></Image>
                        }
                      ></Anchor>

                      <Anchor
                        href="https://twitter.com/statefulArt"
                        target={"_blank"}
                        icon={<Twitter color="#1DA1F2" />}
                      ></Anchor>
                      <Anchor
                        href="https://github.com/stateful-art/"
                        target={"_blank"}
                        icon={<Github color="black" />}
                      ></Anchor>

                      {/* <Anchor
                        href="https://miro.com/app/board/uXjVOXeggvw=/?invite_link_id=88536124274"
                        target={"_blank"}
                        icon={<Stakeholder color="black" />}
                      /> */}
                    </Box>
                  </CardFooter>
                </Card>
              </Box>
            </Box>
            <Box direction="row" gap="medium">
              <Box direction="row" gap="medium"></Box>
            </Box>
          </Box>
        )
      }
    </ResponsiveContext.Consumer>
  );
};

const RootTabs = () => {
  return (
    <>
      <ProjectCards />
    </>
  );
};

const Links = () => {
  return (
    <>
      <ResponsiveContext.Consumer>
        {(size) =>
          size === "small" ? (
            <Box>
              <Paragraph size="medium" textAlign="end">
                Hello ~ my name is Abbas Tolgay. 
                <br></br>
                I'm an '89 born generalist,
                specialized on software engineering and arts.
                <br></br> <br></br>I believe in humanity and I want humanity to
                believe in itself too.
                <br></br> <br></br>

                <Box direction="row" gap="large" justify="center" >
                <Anchor href="https://art.atyilmaz.com" target="_blank" color="red" size="xlarge">art</Anchor> 
                <Anchor href="https://linktr.ee/streamerd" target="_blank" color="red" size="xlarge">
                  linktree
                </Anchor>
                <Anchor
                  href="https://atyilmaz.com/cv/atyilmaz.pdf"
                  target="_blank"
                  color="red"
                  size="xlarge"
                >
                  cv
                </Anchor>
                </Box>
              
              </Paragraph>
            </Box>
          ) : (
            <Box direction="column">
              <Paragraph textAlign="start">
                Hello ~ my name is Abbas Tolgay. 
                <br></br> 
                I'm an '89 born generalist,
                specialized on software engineering and arts.
                <br></br> <br></br>I believe in humanity and I want humanity to
                believe in itself too. 
                <br></br>
                <br></br>
                <Anchor href="https://art.atyilmaz.com"> artworks</Anchor>
                <br></br> <br></br>
                <Anchor href="https://linktr.ee/streamerd" target="_blank">
                  linktree
                </Anchor>{" "}
                <br></br>
                <br></br>
                <Anchor
                  href="https://atyilmaz.com/cv/atyilmaz.pdf"
                  target="_blank"
                >
                  cv
                </Anchor>{" "}
              </Paragraph>
            </Box>
          )
        }
      </ResponsiveContext.Consumer>
    </>
  );
};

const RabbitHoledCard = () => {
  return (
    <ResponsiveContext.Consumer>
      {(size) =>
        size === "small" ? (
          <Card height="small" width="small" background="black" round="medium">
            <CardBody>
              <Box direction="column">
                <Box align="center">
                  <Image src="./rabbit-holed_01.jpeg" width="100%"></Image>
                </Box>
                <Box align="center" margin={"small"}></Box>
              </Box>
            </CardBody>
          </Card>
        ) : (
          <Card
            width="medium"
            round="medium"
          >
            <CardBody>
              <Image
                src="./rabbit-holed_01.jpeg"
                width="100%"
              ></Image>
            </CardBody>
          </Card>
        )
      }
    </ResponsiveContext.Consumer>
  );
};

export default App;
